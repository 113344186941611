<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item class="ui-form__item" name="showPosition" label="展示位置">
						<a-select v-model:value="formState.showPosition" placeholder="请选择展示位置" allow-clear
							style="width: 180px;">
							<a-select-option :value="1">电影 - 选座</a-select-option>
							<a-select-option :value="2">电影 - 电影页</a-select-option>
							<a-select-option :value="3">演出 - 首页</a-select-option>
							<a-select-option :value="4">商城 - 首页</a-select-option>
							<a-select-option :value="5">个人中心 - 主页</a-select-option>
							<a-select-option :value="6">个人中心 - 一卡通充值</a-select-option>
							<a-select-option :value="7">个人中心 - 绑定卡券</a-select-option>
							<a-select-option :value="8">小程序首页</a-select-option>
							<a-select-option :value="11">通用</a-select-option>
							<a-select-option :value="12">演出 - 抢票动向</a-select-option>
							<a-select-option :value="13">演出 - 福利站</a-select-option>
							<a-select-option :value="14">影院定制服务</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="isDisabled" label="状态">
						<a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear
							style="width: 180px;">
							<a-select-option :value="0">启用</a-select-option>
							<a-select-option :value="1">禁用</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="['operation_advertise_list_add']" type="primary"
							@click="onAdd">新增</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" :rowKey="record => record.key"
					:scroll="{ x: 950 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'showPosition'">
							<div>{{ _transPosition(record.showPosition) }}</div>
						</template>
						<template v-if="column.key === 'jumpPosition'">
							<div>{{ _transJumpType(record.jumpPosition) }}</div>
						</template>
						<template v-if="column.key === 'imageUrl'">
							<a-image :width="50" :src="record.imageUrl" />
						</template>
						<template v-if="column.key === 'isDisabled'">
							<a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
							<a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['operation_advertise_list_edit']" @click="onEdit(record)">
											<a-menu-item>
												编辑
											</a-menu-item>
										</div>
										<div v-permission="['operation_advertise_list_open']" v-if="record.isDisabled"
											@click="onDisabled(record)">
											<a-menu-item>
												启用
											</a-menu-item>
										</div>
										<div v-permission="['operation_advertise_list_close']" v-else
											@click="onDisabled(record)">
											<a-menu-item>
												禁用
											</a-menu-item>
										</div>
										<div v-permission="['operation_advertise_list_del']" @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>

	</div>
	<temp v-if="showModal" :isEdit="isEdit" :id="id" @back="onBack"></temp>
</template>

<script>
	import temp from './temp.vue'
	import {
		Icon
	} from '@/components/icon/icon.js';
	import {
		deletePlatformAdImage,
		getPlatformAdImageList,
		updatePlatformAdImage
	} from "../../../../service/modules/operation";
	export default {
		components: {
			Icon,
			temp
		},
		data() {
			return {
				showModal: false,
				isEdit: false,
				isSee: false,
				id: 0,
				loading: false,
				showAll: false,
				searchData: {},
				formState: {},
				columns: [{
					title: '广告图标题',
					dataIndex: 'name',
				}, {
					title: '展示位置',
					key: 'showPosition',
				}, {
					title: '跳转位置',
					key: 'jumpPosition',
				}, {
					title: '图片',
					key: 'imageUrl',
				}, {
					title: '状态',
					key: 'isDisabled',
				}, {
					title: '优先级',
					dataIndex: 'sort',
				}, {
					title: '操作',
					key: 'action',
					width: 150
				}],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getPlatformAdImageList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onAdd() {
				this.id = 0;
				this.showModal = true;
				this.isEdit = false;
			},
			onEdit(item) {
				this.id = item.id;
				this.showModal = true;
				this.isEdit = true;
			},
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: '确认' + (item.isDisabled ? '启用' : '禁用') + '该广告图吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await updatePlatformAdImage({
								id: item.id,
								isDisabled: item.isDisabled ? 0 : 1
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确认删除该广告图吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await deletePlatformAdImage({
								id: item.id
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>